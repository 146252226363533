<template>
  <article
    class="notification"
    data-t="notification-item"
    :class="{ compact }"
    @click="handleNotificationClick"
  >
    <div class="wrapper">
      <div class="logo">
        <slot name="icon">
          <div class="icon-container">
            <StIcon :size="20" :name="icon.name" />
            <div
              v-if="icon.currencyIcon"
              class="currency-icon"
              data-t="currency-icon"
            >
              <StIcon :name="icon.currencyIcon" :size="16" />
            </div>
          </div>
        </slot>
      </div>
      <div class="info">
        <header class="header">
          <h4 class="title">
            <span data-t="subject">
              <slot name="subject">
                {{ t('notifications.defaultSubject') }}
              </slot>
            </span>
            <StIcon
              v-if="!notification.read && !compact"
              class="live"
              :size="16"
              name="live"
            />
          </h4>
          <span v-if="!compact" class="time">{{ time }}</span>
        </header>
        <div class="body" data-t="body-message">
          <slot></slot>
        </div>
      </div>
    </div>
    <div v-if="compact" class="close" @click="read">
      <StIcon :size="16" name="cross-large" />
    </div>
  </article>
</template>

<script setup lang="ts">
import type { IconName } from '@st/ui/components/StIcon/types'
import { useNotification } from '../../../composables/useNotification'
import type { Notification } from '../../../types'

interface Props {
  notification: Notification
  compact?: boolean
  icon?: {
    name?: IconName | null | ''
    currencyIcon?: IconName | null | ''
  }
  link?: string
}

const {
  compact = false,
  notification,
  icon = {
    name: 'support-solid' as const,
    currencyIcon: null,
  },
  link = '',
} = defineProps<Props>()

const { t } = useI18n()

const notificationItem = toRef(() => notification)
const { time, readNotification } = useNotification(notificationItem)

async function read() {
  await readNotification()
  notificationItem.value.read = true
}

async function handleNotificationRead() {
  if (compact) return

  read()
}

const router = useRouter()
async function handleNotificationClick() {
  await handleNotificationRead()

  if (link) {
    router.push(link)
  }
}
</script>

<style scoped>
.icon-container {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 36px;
  height: 36px;

  background: var(--background-base);
  border-radius: var(--border-radius-full);
}

.currency-icon {
  position: absolute;
  right: -4px;
  bottom: -4px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: var(--background-tertiary);
  border: 2px solid var(--background-primary);
  border-radius: var(--border-radius-full);
}

.body {
  :slotted(p) {
    margin: 0;
    font: var(--mobile-text-content-regular);
    color: var(--text-tertiary);

    b {
      font: var(--mobile-text-content-regular);
      color: var(--text-primary);
    }
  }
}

.notification {
  cursor: pointer;

  position: relative;

  overflow: hidden;
  display: flex;
  flex: 1 0 0;
  gap: var(--spacing-150);
  align-items: flex-start;

  min-width: 0;
  padding: var(--spacing-125) var(--spacing-150);

  background: var(--background-primary);
  border-radius: var(--border-radius-100);

  &:hover {
    background: var(--background-secondary);
  }

  &.compact {
    cursor: default;
    padding-right: var(--spacing-500);

    &::after {
      content: '';

      position: absolute;
      z-index: 1;
      right: var(--spacing-400);
      bottom: 0;
      left: 0;

      display: block;

      height: 3px;

      background-color: var(--background-tertiary);
    }

    &::before {
      content: '';

      position: absolute;
      z-index: 2;
      right: var(--spacing-400);
      bottom: 0;
      left: 0;

      display: block;

      height: 3px;

      background-color: var(--button-ghost-default);

      animation: progress 3.5s linear forwards;
    }
  }
}

.header {
  display: flex;
  gap: var(--spacing-050);
  align-items: flex-end;
  margin-bottom: var(--spacing-050);
}

.title {
  display: inline-block;

  margin: 0;

  color: var(--text-primary);
  white-space: nowrap;
  line-height: 1; /* stylelint-disable-line */

  span {
    font: var(--desktop-text-sm-semibold);
    white-space: normal;
    vertical-align: middle;
  }

  .live {
    margin-left: var(--spacing-050);
    color: #fe5e01; /* stylelint-disable-line */
    vertical-align: middle;
  }
}

.logo {
  display: flex;
  align-items: center;
  margin-top: var(--spacing-025);
}

.wrapper {
  display: flex;
  flex-grow: 1;
  gap: var(--spacing-150);
  align-items: flex-start;
}

.time {
  overflow: hidden;

  min-width: 88px;
  margin-left: auto;

  font: var(--desktop-text-xs-medium);
  color: var(--text-tertiary);
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.info {
  flex-grow: 1;
}

.action-button {
  margin-top: var(--spacing-150);
}

.close {
  cursor: pointer;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 32px;

  color: var(--text-tertiary);

  border-left: 1px solid rgb(255 255 255 / 4%);
}

@keyframes progress {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}
</style>
